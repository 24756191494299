import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation from react-router-dom
import "./Sidebar.css"; // Make sure to define the styles here
import "../../../assets/vendor/css/rtl/theme-default.css";
import logo from "../../../assets/favicon.png";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const sidebarRef = useRef(null);
  const location = useLocation(); // Get the current location

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        isOpen
      ) {
        toggleSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleSidebar]);

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`} ref={sidebarRef}>
      <div className="sidebar-content">
        <div className="row align-items-center">
          <div className="col-auto pe-0">
            <div className="avatar me-2">
              <img
                src={logo}
                alt="autoprofito Logo"
                className="w-px-40 h-auto rounded-circle"
              />
            </div>
          </div>
          <div className="col px-0">
            <span className="app-brand-text menu-text fw-semibold fs-6">
              StockSync
            </span>
          </div>
          <div className="col-auto">
            <button onClick={toggleSidebar} className="close-btn text-end">
              <i className="ri-close-line"></i>
            </button>
          </div>
        </div>
        <ul className="sidebar-menu">
          <li
            className={`menu-item ${
              location.pathname === "/user_basket" ? "active" : ""
            }`}
          >
            <Link to="/user_basket">
              <i className="menu-icon ri-shopping-basket-line"></i> Basket
            </Link>
          </li>
          <li
            className={`menu-item ${
              location.pathname === "/order_book" ? "active" : ""
            }`}
          >
            <Link to="/order_book">
              <i className="menu-icon ri-book-marked-line"></i> Order Book
            </Link>
          </li>
          <li
            className={`menu-item ${
              location.pathname === "/trade_book" ? "active" : ""
            }`}
          >
            <Link to="/trade_book">
              <i className="menu-icon ri-swap-2-line"></i> Trade Book
            </Link>
          </li>
          <li
            className={`menu-item ${
              location.pathname === "/trade_position" ? "active" : ""
            }`}
          >
            <Link to="/trade_position">
              <i className="menu-icon ri-stock-line"></i> Position
            </Link>
          </li>
          <li
            className={`menu-item ${
              location.pathname === "/teacher/manage_student" ? "active" : ""
            }`}
          >
            <Link to="/teacher/manage_student">
              <i className="menu-icon ri-group-3-line"></i> Manage Student
            </Link>
          </li>
          {/* <li
            className={`menu-item ${
              location.pathname === "/teacher/watchlist" ? "active" : ""
            }`}
          >
            <Link to="/teacher/watchlist">
              <i className="menu-icon ri-group-3-line"></i> Watchlist
            </Link>
          </li> */}
          {/* Add more links as needed */}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
