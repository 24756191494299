import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer"
import AdminSubHeader from "./AdminSubHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import config from "../../../component/config";
import { Tooltip } from "primereact/tooltip";
const TeacherReportDetails = () => {
  const { teacherId, sellDate } = useParams();
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");
  // Fetch teacher_id and sell_month from URL params
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [backClicked, setBackClicked] = useState(false);
  const [error, setError] = useState(null);
  const [summary, setSummary] = useState({
    total_trades_count: 0,
    total_profitable_trades: 0,
    total_losing_trades: 0,
    total_commission: 0.0,
  });

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${config.apiDomain}/api/admin/teacher_trade_details`,
        {
          teacher_id: teacherId,
          sell_date: sellDate,
        },
        {
          headers: {
            "Authorization": `Bearer ${authToken}`
          }
        }
      );

      if (response.data) {
        setData(response.data.trades);
   
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      setError(new Error(error.message || "Failed to fetch data"));
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  useEffect(() => {
    fetchData();
  }, [teacherId, sellDate]);

  
  const buyPriceTemplate = (rowData) => {
    return ` ${rowData.buy_price} Rs.`;
  };

  const sellPriceBodyTemplate = (rowData) => {
    return ` ${rowData.sell_price} Rs.`;
  };

  const PLTemplate = (rowData) => {
    return ` ${rowData.pandl} Rs.`;
  };

  const PLTotalBodyTemplate = (rowData) => {
    return ` ${rowData.pandl_total} Rs.`;
  };

  const PLPercentTemplate = (rowData) => {
    return ` ${rowData.pandl_percent} %`;
  };

  const CommissionBodyTemplate = (rowData) => {
    return ` ${rowData.commission} %`;
  };

  const formatDateTime = (datetime) => {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    return new Date(datetime).toLocaleTimeString('en-US', options);
  };
  
  const formatDateDay = (datetime) => {
    return new Date(datetime).getDate();
  };
  
  const formatDateMonth = (datetime) => {
    return new Date(datetime).toLocaleString('default', { month: 'short' });
  };
  
  const formatDateYear = (datetime) => {
    return new Date(datetime).getFullYear();
  };
  
  
  const buyColumnTemplate = (rowData) => {
    return (
      <div>
        <div className="mb-1">
          <strong>{rowData.entry_type}</strong>
          {" = "} <strong>{rowData.entry_price} ₹</strong> | qty:
          <strong>
            {" "}
            {rowData.entry_lotsize}({rowData.entry_stock_quantity})
          </strong>
        </div>

        <div className="mb-1">{rowData.entry_datetime}
          {" | "}
        <span>{rowData.entry_button_type}</span>  
        </div>
        <div className="mb-1">
          <strong>{rowData.stock_symbol}</strong>
        </div>
      </div>
    );
  };
  
  const sellColumnTemplate = (rowData) => {
    return (
      <div>
        <div className="mb-1">
          <strong>{rowData.exit_type}</strong>
          {" = "}
          <strong>{rowData.exit_price} ₹ </strong> | qty:
          <strong>
            {" "}
            {rowData.exit_lotsize}({rowData.exit_stock_quantity})
          </strong>
        </div>
        <div className="mb-1">{rowData.exit_datetime}
{" | "}
        <span>{rowData.exit_button_type}</span>
        </div>
        <div className="mb-1">
          <strong>{rowData.stock_symbol}</strong>
        </div>
      </div>
    );
  };
 
  return (
    <>
      <AdminHeader />
      <AdminSubHeader />

      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-secondary">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-secondary">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/profile" className="text-secondary">
                profile
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/teacher_list" className="text-secondary">
                Teacher Report
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/teacher_report" className="text-secondary">
               Monthwise Teacher Report
              </Link>
            </li>
            <li className="breadcrumb-item text-secondary">
               Datewise Teacher Report
             
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
              Teacher Report Details
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col text-start mb-5">
              <h5 className="mb-0"> Teacher Report Details</h5>
            </div>
          </div>
          <div className="row text-center">
  <div className="col-md-4">
    <h4 style={{ marginBottom: '4px' }}>{summary.total_trades_count}</h4>
    <p style={{ marginTop: '0px' }}>Total Trades</p>
  </div>
  <div className="col-md-4">
    <h4 style={{ marginBottom: '4px' }}>{summary.total_profitable_trades}</h4>
    <p style={{ marginTop: '0px' }}>Profitable Trades</p>
  </div>
  <div className="col-md-4">
    <h4 style={{ marginBottom: '4px' }}>{summary.total_losing_trades}</h4>
    <p style={{ marginTop: '0px' }}>Losing Trades</p>
  </div>
  {/* <div className="col-md-3">
    <h4 style={{ marginBottom: '4px' }}>{summary.total_commission} Rs.</h4>
    <p style={{ marginTop: '0px' }}>Commission</p>
  </div> */}
</div>

          


          <div className="d-flex justify-content-between align-items-center mb-3">
  <div className="d-flex align-items-center">
    <IconField iconPosition="left">
      <InputIcon className="ri ri-search-line"></InputIcon>
      <InputText
        type="search"
        placeholder="Search"
        value={globalFilter}
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="rounded m"
      />
    </IconField>

    {loading ? (
                  <div className="d-flex align-items-center ms-3">
                  <div
                    className="spinner-border spinner-border-sm custom-spinner ms-3"
                    role="status"
                    
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="">
                  <Tooltip target=".custom-target-icon" />
                  <i
                    className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary" role="button"
                    data-pr-tooltip="Reload"
                    onClick={fetchData}
                    data-pr-position="top"
                   
                  ></i>
                </div>
    )}
  </div>

  <strong className="mt-3 ms-auto  fs-4">{sellDate}</strong> {/* Display month on the right */}
</div>
          <DataTable
            style={{ border: "1px solid #ddd" }}
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
          >
            

<Column
    align="left"
      className="custom-column-border"
      style={{ border: "1px solid #ddd" }}

    header="Entry"
    body={buyColumnTemplate}
  ></Column>

  <Column
    align="left"
    style={{ border: "1px solid #ddd" }}

     className="custom-column-border"
    header="Exit"
    body={sellColumnTemplate}
  ></Column>
         
            {/* <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="pandl"
              header="P&L"
              body={PLTemplate}
            ></Column> */}
            <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="pandl_total"
              header="P&L Total"
              body={ PLTotalBodyTemplate} 
            ></Column>
            <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="pandl_percent"
              header="P&L Percent"
              body={PLPercentTemplate}
            ></Column>
            {/* <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="commission"
              header="Commission "
              body={CommissionBodyTemplate}
            ></Column> */}
          </DataTable>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default TeacherReportDetails;
