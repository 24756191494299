import { Bar } from "react-chartjs-2";
import config from "../../../component/config";
import { Button } from "primereact/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import Header from "../component/Header";
import Footer from "../../../component/Footer";
import SubHeader from "../component/SubHeader";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
const ViewStudent = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Use useParams to get the ID from the route
  const [backClicked, setBackClicked] = useState(false);
  const toast = useRef(null);
  const [teacherData, setTeacherData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [logs, setLogs] = useState([]); // State for storing logs
  const [showLogsModal, setShowLogsModal] = useState(false); // State to control modal visibility

  const [filterType, setFilterType] = useState("All"); // Default filter is "All"

  // Function to handle filter button clicks
  const handleFilterClick = (type) => {
    setFilterType(type);
  };

  // Filter logs based on the selected filter type
  const filteredLogs = logs.filter((log) => {
    if (filterType === "All") return true;
    return log.type === filterType;
  });

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const fetchLogs = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        navigate("/commonlogin");
        return;
      }
      const userId = localStorage.getItem("userId");
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/get_students_all_message_logs`,
        { teacher_id: userId, student_id: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data && response.data.st === 1) {
        setLogs(response.data.logs);
        setShowLogsModal(true); // Show modal after data is fetched
      } else if (response.data.st >= 2 && response.data.st <= 4) {
        // Display server message in toast if st is between 2 and 4
        const statusMessage = `st=${response.data.st}: ${
          response.data.msg || "No specific message"
        }`;
        toast.current.show({
          severity: "warn",
          summary: "Notice",
          detail: statusMessage,
          life: 3000,
        });
      } else {
        throw new Error("Failed to fetch logs");
      }
    } catch (error) {
      // Check if error response exists and contains a message
      const errorMessage = error.response?.data?.msg || "Failed to fetch logs";
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const authToken = localStorage.getItem("authToken"); // Fetch the auth token from localStorage
        if (!authToken) {
          navigate("/commonlogin");
          return;
        }

        const response = await axios.post(
          `${config.apiDomain}/api/teacher/manage_students/view`,
          {
            student_id: id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
            },
          }
        );

        if (response.data && response.data.st === 1) {
          setTeacherData(response.data.data);
        } else {
          setError(new Error(response.data.msg || "Failed to fetch data"));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Check for 401 Unauthorized error
        if (error.response && error.response.status === 401) {
          console.error(
            "Unauthorized access, redirecting to common login screen"
          );
          navigate("/commonlogin"); // Navigate to common login screen on 401 error
        } else {
          const errorMsg = error.response
            ? error.response.data.msg || "Failed to fetch data"
            : error.message || "Failed to fetch data";
          setError(new Error(errorMsg));
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMsg,
            life: 3000,
          });
        }
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <Header />
      <SubHeader />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-secondary">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-secondary">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/teacher/manage_student" className="text-secondary">
                Manage Student
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              View Student
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center mb-5">
            <div className="col-3 text-start">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-center">
              <h5 className="mb-0">View Student</h5>
            </div>
            <div className="col-3 text-end">
              <button onClick={fetchLogs} className="btn btn-primary active">
                Message Log
              </button>
            </div>
          </div>

          <div
            className={`modal fade ${showLogsModal ? "show d-block" : ""}`}
            tabIndex="-1"
            role="dialog"
            style={{ display: showLogsModal ? "block" : "none" }}
            onClick={() => setShowLogsModal(false)}
          >
            <div
              className="modal-dialog modal-xl"
              role="document"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modal-content">
                <div className="modal-header mb-4">
                  <h5 className="modal-title">Message Log</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setShowLogsModal(false)}
                  ></button>
                </div>
                <div className="col-md-12 col-lg-12 text-center mb-3">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      className={`btn ${
                        filterType === "All"
                          ? "btn-outline-success"
                          : "btn-outline-secondary"
                      }`}
                      onClick={() => handleFilterClick("All")}
                    >
                      All
                    </button>
                    <button
                      type="button"
                      className={`btn ${
                        filterType === "Entry"
                          ? "btn-outline-success"
                          : "btn-outline-secondary"
                      }`}
                      onClick={() => handleFilterClick("Entry")}
                    >
                      Entry
                    </button>
                    <button
                      type="button"
                      className={`btn ${
                        filterType === "Exit"
                          ? "btn-outline-success"
                          : "btn-outline-secondary"
                      }`}
                      onClick={() => handleFilterClick("Exit")}
                    >
                      Exit
                    </button>
                  </div>
                </div>
                <div
                  className="modal-body"
                  style={{ maxHeight: "400px", overflowY: "auto" }}
                >
                  {filteredLogs.length > 0 ? (
                    filteredLogs.map((log, index) => (
                      <div
                        key={index}
                        className="py-1 px-2 mb-3 border border-1 rounded"
                      >
                        <div className="row">
                          <span className="col-6 fw-bold">
                            {log.type} : {log.sub_type}
                          </span>
                          <div className="col-6 text-end">
                            <small className="text-muted text-end">
                              {log.datetime}
                            </small>
                          </div>
                        </div>
                        <p className="mb-1">{log.description}</p>
                      </div>
                    ))
                  ) : (
                    <p>No logs available for the selected filter.</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Modal Background */}
          {showLogsModal && (
            <div
              className="modal-backdrop fade show"
              onClick={() => setShowLogsModal(false)}
            ></div>
          )}

          {loading && <div>Loading...</div>}
          {error && <div>{error.message}</div>}
          {teacherData && (
            <div className="row">
              <div className="col-12">
                <div className="row mt-1">
                  <h4>
                    {" "}
                    <i className="ri-user-line ri-ms me-1 "></i>
                    Personal Information
                  </h4>
                  <div className="col-3">
                    <span className="text-black">
                      <strong>{toTitleCase(teacherData.name)}</strong>
                    </span>
                    <div>Name</div>
                  </div>
                  <div className="col-3">
                    <span className="text-black">
                      <strong>{teacherData.mobile}</strong>
                    </span>
                    <div>Mobile</div>
                  </div>
                  <div className="col-3">
                    <span className="text-black">
                      <strong>{teacherData.email}</strong>
                    </span>
                    <div>Email</div>
                  </div>
                  <div className="col-3">
                    <span
                      className={` ${
                        teacherData.broker_status
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {teacherData.broker_status ? (
                        <i
                          className="ri-shield-check-line"
                          style={{ marginRight: "5px" }}
                        ></i>
                      ) : (
                        <i
                          className="ri-close-large-line"
                          style={{ marginRight: "5px" }}
                        ></i>
                      )}
                      <strong>
                        {teacherData.broker_status
                          ? "Connected"
                          : "Disconnected"}
                      </strong>
                    </span>
                    <div>Broker Status</div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-5 mb-5">
                <div className="row mt-1">
                  <div className="col-3">
                    <span
                      className={` ${
                        teacherData.trade_status
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {teacherData.trade_status && (
                        <i
                          className="ri-checkbox-multiple-line"
                          style={{ marginRight: "5px" }}
                        ></i>
                      )}
                      <strong>
                        {teacherData.trade_status ? "Active" : "Inactive"}
                      </strong>
                    </span>
                    <div>Trade Status</div>
                  </div>
                  <div className="col-3">
                    <span className="text-black">
                      <strong>
                        {(teacherData.amount || 0).toFixed(2)} Rs.
                      </strong>
                    </span>
                    <div>Broker Acc. Balance</div>
                  </div>

                  <div className="col-3">
                    <span className="text-black">
                      <strong>{teacherData.lot_size_limit} Lot</strong>
                    </span>
                    <div>Lot Size Limit</div>
                  </div>

                  <div className="col-3">
                    <span className="text-black">
                      <strong>{teacherData.commission}%</strong>
                    </span>
                    <div>Commission</div>
                  </div>
                </div>
              </div>

              <hr />
              <h4>
                {" "}
                <i className="ri-group-line ri-ms me-2"></i>
                Broker Information
              </h4>
              <div className="col-12 mt-5 mb-5">
                <div className="row mt-1">
                  <div className="col-3">
                    <span className="text-black">
                      <strong>{teacherData.broker_api_key}</strong>
                    </span>
                    <div>Broker API Key</div>
                  </div>

                  <div className="col-3">
                    <span className="text-black">
                      <strong>{teacherData.broker_client_id}</strong>
                    </span>
                    <div>Broker Client ID</div>
                  </div>

                  <div className="col-3">
                    <span className="text-black">
                      <strong>{teacherData.broker_password}</strong>
                    </span>
                    <div>Broker Password</div>
                  </div>

                  <div className="col-3 ">
                    <span className="text-black">
                      <strong>{teacherData.broker_qr_totp_token}</strong>
                    </span>
                    <div>Broker QR TOTP Token</div>
                  </div>
                </div>
              </div>
              <hr></hr>
            </div>
          )}
        </div>
        <hr />
      </div>
      <Footer />
    </>
  );
};

export default ViewStudent;
