import React, { useState, useEffect,  useRef } from "react";

import {  Row, Col, Nav,  Card, Modal } from "react-bootstrap";
import axios from "axios";
import config from "../../../component/config";
import Header from "../component/Header";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../../component/Footer";
import SubHeader from "../component/SubHeader";

import { Toast } from "primereact/toast";
import { Tooltip } from "primereact/tooltip";
const WatchList = () => {
  const [search, setSearch] = useState("");
  const [equityData, setEquityData] = useState([]);
  const [commodityData, setCommodityData] = useState([]);
  const [fnoData, setFnoData] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const [selectedExchange, setSelectedExchange] = useState([]);
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const [isTableVisible, setIsTableVisible] = useState(false);
  const authToken = localStorage.getItem('authToken');
  const [equityCount, setEquityCount] = useState(0);
  const [commodityCount, setCommodityCount] = useState(0);
  const [fnoCount, setFnoCount] = useState(0);
 
  const toast = useRef(null);
  const [watchList, setWatchList] = useState([]);
  const [loading, setLoading] = useState(true);

  const userId = localStorage.getItem('userId');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  useEffect(() => {
    const fetchWatchList = async () => {
      setLoading(true);
      try {
        const authToken = localStorage.getItem('authToken');
        const response = await axios.post(
          `${config.apiDomain}/api/teacher/watchlist_view`,
          { teacher_id: userId },
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );

        if (response.data.st === 1) {
          setWatchList(response.data.watchlist);

        } else {

        }
      } catch (err) {
        
        if (err.response && err.response.status === 401) {
          navigate('/commonlogin');  
        } else {
       
        }
      } finally {
        setLoading(false);
      }
    };
    fetchWatchList();
  }, [userId]);

  const handleRefresh = async () => {
    setLoading(true);
    setIsRefreshing(true);
    try {
      const authToken = localStorage.getItem('authToken');
      const response = await axios.post(
       `${config.apiDomain}/api/teacher/watchlist_view`,
        { teacher_id: userId },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );

      if (response.data.st === 1) {
        setWatchList(response.data.watchlist);

      } else {

      }
    } catch (err) {
    } finally {
      setLoading(false);
      setIsRefreshing(false);
    }
  };
  const handleDelete = async (token) => {
    try {
      const authToken = localStorage.getItem('authToken');
      const response = await axios.delete(
        `${config.apiDomain}/api/teacher/watchlist_delete`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          data: { teacher_id: userId, token: token }, 
        }
      );

      if (response.data.st === 1) {

        setWatchList((prevList) => prevList.filter((item) => item.token !== token));
      } else {

      }
    } catch (err) {
    }
  };
  const openDeleteModal = (token) => {
    setSelectedToken(token);
    setShowDeleteModal(true);
  };


  const closeDeleteModal = () => {
    setShowDeleteModal(false); 
    setSelectedToken(null); 
  };

 
  const confirmDelete = () => {
    handleDelete(selectedToken); 
    closeDeleteModal(); 
  };
  const handleSearch = async (e) => {
    let searchValue = e.target.value.toUpperCase(); 
    
   
    if (searchValue.length > 15) {
      searchValue = searchValue.slice(0, 15); 
    }
    
    setSearch(searchValue);  
    
   
    if (searchValue.trim().length >= 3) {
      try {
        const response = await axios.post(
          `${config.apiDomain}/api/teacher/get_instrument_list_new`,
          {
            search: searchValue,  
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );
    
        if (response.data.st === 1) {
          const equity = response.data.data.equity || [];
          const commodity = response.data.data.commodity || [];
          const fno = response.data.data.fno || [];
    
          setEquityData(equity);
          setCommodityData(commodity);
          setFnoData(fno);
          setShowDropdown(true);
    
          const instrumentList = [...equity, ...commodity, ...fno].map((item) => item.symbol);
          setItems(instrumentList);
    
          // Calculate the counts
          setEquityCount(equity.length);
          setCommodityCount(commodity.length);
          setFnoCount(fno.length);
        } else {
          resetSearchData();
        }
      } catch (error) {
        console.error("Error fetching instrument list", error);
      }
    } else {
      // Clear search results if fewer than 3 characters
      resetSearchData();
    }
  };
  
  
  // Helper function to reset search-related states
  const resetSearchData = () => {
    setEquityData([]);
    setCommodityData([]);
    setFnoData([]);
    setShowDropdown(false);
    setItems([]);
    setEquityCount(0);
    setCommodityCount(0);
    setFnoCount(0);
  };
  
  const handleStockSelect = async (stock) => {

    if (watchList.length >= 30) {
     
    
      toast.current.show({
        severity: "error",
        summary: " Error",
        detail: "Watchlist full.",
        life: 3000,
      });
      setShowDropdown(false);
      return;
    }
    setSelectedStock(stock);
    setSearch('');
    setShowDropdown(false);
  
    const watchlistData = {
      teacher_id: userId,
      token: stock.token,
    };
  
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/create_watchlist`,
        watchlistData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.data.st === 1) {
        setWatchList((prevList) => {
          if (!prevList.some((item) => item.token === stock.token)) {
            return [...prevList, stock]; // Add new stock
          }
          return prevList;
        });
  
        setIsTableVisible(true);
      }
    } catch (error) {
      console.error("Error creating watchlist entry", error);
    }
  };
  
  const handleExchangeSelect = (exchange) => {
    setSelectedExchange(exchange);
  };
  


  const filteredStocks = (() => {
    switch (selectedExchange) {
      case "Equity":
        return equityData;
      case "FnO":
        return fnoData;
      case "Commodity":
        return commodityData;
      default:
        return [...equityData, ...fnoData, ...commodityData];
    }
  })();
  return (
    <div>
      <Toast ref={toast} />
      <Header></Header>
      <SubHeader></SubHeader>
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <div className="container-xxl flex-grow-1 container-p-y">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1 text-black">
                <li className="breadcrumb-item">
                  <Link to="/teacher/dashboard" className="text-black">
                    <i className="ri-home-7-line ri-lg"></i>
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active text-secondary"
                  aria-current="page"
                >
                  Watchlist
                </li>
              </ol>
            </nav>
            <div className="row">
              <div className="col-xl-9 col-lg-9  col-9 ">
                <div className="card">
                  <div className="d-flex justify-content-start ms-4 mb-3  mt-2 me-3"></div>

                  <div
                    className="card-datatable table-responsive pt-0 "
                    style={{ height: "400px", overflowY: "auto" }}
                  ></div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3  col-3 ">
                <div className="card ">
                  <div className="d-flex justify-content-between mt-3 ms-3 me-3 bg-white ">
                    <div>
                      <span className="text-black">NIFTY 50</span>
                      <span className="text-success ms-2">10120.75</span>
                    </div>
                    <div>
                      <span className="text-black">SENSEX</span>
                      <span className="text-success ms-2">586.90</span>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="ms-1 me-1 " style={{ position: "relative" }}>
                    <div className="d-flex justify-content-start ms-4 mb-3 me-3">
                      <div className="flex-grow-1 input-group input-group-sm input-group-merge rounded-pill">
                        <span
                          className="input-group-text"
                          id="basic-addon-search31"
                        >
                          <i className="ri-search-line lh-1 ri-20px"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control chat-search-input ps-8"
                          placeholder="Search instruments..."
                          value={search.toUpperCase()}
                          onChange={handleSearch}
                          aria-label="Search..."
                          aria-describedby="basic-addon-search31"
                          maxLength={15}
                        />
                      </div>
                      {loading ? (
                        <div className="d-flex align-items-center">
                          <i
                            className={`ri-refresh-line ri-lg me-3 p-text-secondary ${
                              isRefreshing ? "rotate" : ""
                            }`}
                            role="status"
                          ></i>
                        </div>
                      ) : (
                        <div className="mt-3">
                          <Tooltip target=".custom-target-icon" />
                          <i
                            className="custom-target-icon ri ri-refresh-line ri-lg me-3 p-text-secondary"
                            role="button"
                            data-pr-tooltip="Reload"
                            onClick={handleRefresh}
                            data-pr-position="top"
                          ></i>
                        </div>
                      )}
                    </div>
                    {showDropdown && search && filteredStocks.length > 0 && (
                      <Card
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: 0,
                          width: "100%",
                          zIndex: 1000,
                        }}
                      >
                        <Nav
                          justify
                          variant="tabs"
                          className="bg-body"
                          style={{ height: "45px", overflowX: "hidden" }}
                        >
                          {equityCount > 0 && (
                            <Nav.Item>
                              <Nav.Link
                                className={`custom-nav-link mt-1 ${
                                  selectedExchange === "Equity" ? "active" : ""
                                }`}
                                onClick={() => handleExchangeSelect("Equity")}
                              >
                                <i className="ri-cash-line ri-lg me-1"></i>
                                Equity
                                <small
                                  className={`ms-1 ${
                                    selectedExchange === "Equity"
                                      ? "active"
                                      : "text-muted "
                                  }`}
                                >
                                  ({equityCount})
                                </small>
                              </Nav.Link>
                            </Nav.Item>
                          )}

                          {fnoCount > 0 && (
                            <Nav.Item>
                              <Nav.Link
                                className={`custom-nav-link mt-1 ${
                                  selectedExchange === "FnO" ? "active" : ""
                                }`}
                                onClick={() => handleExchangeSelect("FnO")}
                              >
                                <i className="ri-bar-chart-2-line ri-lg me-1"></i>
                                FnO
                                <small
                                  className={`ms-1 ${
                                    selectedExchange === "FnO"
                                      ? "active"
                                      : "text-muted "
                                  }`}
                                >
                                  ({fnoCount})
                                </small>
                              </Nav.Link>
                            </Nav.Item>
                          )}

                          {commodityCount > 0 && (
                            <Nav.Item>
                              <Nav.Link
                                className={`custom-nav-link mt-1 ${
                                  selectedExchange === "Commodity"
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleExchangeSelect("Commodity")
                                }
                              >
                                <i className="ri-vip-diamond-line ri-lg me-1"></i>
                                Commodity
                                <small
                                  className={`ms-1 ${
                                    selectedExchange === "Commodity"
                                      ? "active"
                                      : "text-muted "
                                  }`}
                                >
                                  ({commodityCount})
                                </small>
                              </Nav.Link>
                            </Nav.Item>
                          )}
                        </Nav>

                        <Card.Body
                          className="bg-white p-0"
                          style={{
                            maxHeight: "250px",
                            overflowY: "auto",
                            overflowX: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Row>
                            {filteredStocks.map((stock, i) => (
                              <Col
                                key={i}
                                xs={12}
                                className={`d-flex align-items-center mb-1 ${
                                  selectedStock === stock
                                    ? "bg-body text-black"
                                    : "hover-bg-secondary"
                                } custom-col`}
                                style={{ cursor: "pointer" }}
                                onClick={() => handleStockSelect(stock)}
                              >
                                <div className="custom-col-content ms-3">
                                  <span className="badge rounded-pill bg-label-secondary me-2 ">
                                    {stock.exch_seg}
                                  </span>
                                  <strong>{stock.symbol}</strong>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </Card.Body>
                      </Card>
                    )}
                    <hr className="pb-0 mb-0"></hr>
                  </div>

                  <div
                    className="card-datatable table-responsive pt-0"
                    style={{
                      height: "300px",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <table className="table table-sm">
                      <tbody>
                        {watchList.length > 0 ? (
                          watchList.map((item) => (
                            <tr key={item.token}>
                              <td className="text-danger d-flex align-items-center">
                                <span className="badge rounded-pill bg-label-secondary me-1">
                                  {item.exch_seg}
                                </span>
                                <span>{item.symbol}</span>
                              </td>
                              <td className="text-end">
                                <div className="btn-group">
                                  <i
                                    className="ri ri-arrow-line rounded-pill dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  ></i>

                                  <ul className="dropdown-menu pt-0 pb-0">
                                    <li>
                                      <button
                                        type="button"
                                        className="btn btn-label-success w-100 border-bottom dropdown-item mt-0"
                                      >
                                        <span className="text-start">
                                          <i className="ri-check-line text-success ri-lg me-1"></i>{" "}
                                          Buy
                                        </span>
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        type="button"
                                        className="btn btn-label-danger w-100 border-bottom dropdown-item"
                                      >
                                        <span className="text-start">
                                          <i className="ri-telegram-line text-danger ri-lg me-1"></i>{" "}
                                          Sell
                                        </span>
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        type="button"
                                        className="w-100 btn-xs text-center dropdown-item"
                                        onClick={() =>
                                          openDeleteModal(item.token)
                                        } // Open modal with selected token
                                      >
                                        <i className="ri-close-large-line ri-md me-1"></i>{" "}
                                        Delete
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3" className="text-center">
                              {loading ? "Loading..." : "No watchlist found."}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <DeleteConfirmationModal
                    show={showDeleteModal}
                    handleClose={closeDeleteModal}
                    handleConfirmDelete={confirmDelete}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default WatchList;
const DeleteConfirmationModal = ({ show, handleClose, handleConfirmDelete }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this instrument from the watchlist?</Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <button
            onClick={handleClose}
            className="btn btn-outline-secondary rounded-pill btn-sm"
          >
            <i className="ri-close-large-line me-2"></i>
            Cancel
          </button>
          <button
            onClick={handleConfirmDelete}
            className="btn btn-danger rounded-pill btn-sm"
          >
            <i className="ri-delete-bin-line me-2"></i>
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};