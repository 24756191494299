import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import Footer from "../../../component/Footer";
import { Link, useNavigate } from "react-router-dom";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import config from "../../../component/config";
import AdminHeader from "./AdminHeader";
import { Tooltip } from "primereact/tooltip";
import AdminSubHeader from "./AdminSubHeader";

const AdminOrderBook = () => {
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useRef(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [backClicked, setBackClicked] = useState(false);

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    const userId = localStorage.getItem("userId");
    const authToken = localStorage.getItem("authToken");

    if (!userId) {
      navigate("/commonlogin");
      return;
    }

    if (!authToken) {
      setError(new Error("Auth token not found"));
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${config.apiDomain}/api/common/order_book`,
        { user_id: userId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data && response.data.data) {
        setData(response.data.data);
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    setIsRefreshing(true);
    fetchData(); // Call fetchData to refresh data
  };

  const handleError = (error) => {
    console.error("Error fetching data:", error);
    if (error.response && error.response.status === 401) {
      navigate("/commonlogin");
    } else {
      const errorMsg = error.response
        ? error.response.data.msg || "Failed to fetch data"
        : error.message || "Failed to fetch data";
      setError(new Error(errorMsg));
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMsg,
        life: 3000,
      });
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, []); // Ensure the dependency array is empty for initial fetch

  const rowClassName = (rowData, rowIndex) => {
    return rowIndex % 2 === 0 ? "even-row" : "odd-row";
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const renderTransactionType = (rowData) => {
    const style = {
      color: rowData.transactiontype === "BUY" ? "green" : "orange",
    };
    return <span style={style}>{rowData.transactiontype}</span>;
  };

  const renderOrderStatus = (rowData) => {
    const style = {
      color: rowData.orderstatus === "complete" ? "green" : "orange",
    };
    return <span style={style}>{rowData.orderstatus}</span>;
  };

  return (
    <>
      <AdminHeader />
      <AdminSubHeader />
      <Toast ref={toast} />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-secondary">
            <li className="breadcrumb-item">
              <Link to="/student/dashboard" className="text-secondary">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Order Book
            </li>
          </ol>
        </nav>

        <div className="card p-5">
          <div className="d-flex justify-content-between align-items-center mb-5">
            <button
              onClick={handleBack}
              className="btn rounded-pill btn-outline-secondary btn-xs"
            >
              <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
            </button>
            <h5 className="mb-0 mx-auto">Order Book</h5>
            <div></div>
          </div>
          <div className="d-flex justify-content-end mb-3">
            {loading ? (
              <div className="d-flex align-items-center">
                <i
                  className={`ri-refresh-line ri-lg me-3 p-text-secondary ${
                    isRefreshing ? "rotate" : ""
                  }`}
                  role="status"
                ></i>
              </div>
            ) : (
              <div className="mt-3">
                <Tooltip target=".custom-target-icon" />
                <i
                  className="custom-target-icon ri ri-refresh-line ri-lg me-3 p-text-secondary"
                  role="button"
                  data-pr-tooltip="Reload"
                  onClick={handleRefresh}
                  data-pr-position="top"
                ></i>
              </div>
            )}

            <IconField iconPosition="left">
              <InputIcon className="ri ri-search-line"></InputIcon>
              <InputText
                type="search"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="rounded"
              />
            </IconField>
          </div>
          <DataTable
            className="text-center"
            style={{ border: "1px solid #ddd" }}
            value={data}
            paginator
            rows={20}
            showGridlines
            globalFilter={globalFilter}
            emptyMessage="No records found"
            rowClassName={rowClassName}
          >
            <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="tradingsymbol"
              header="Symbols"
            ></Column>
            <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="transactiontype"
              header="Transaction Type"
              body={renderTransactionType}
            ></Column>
            <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="exchange"
              header="Exchange"
            ></Column>
            <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="instrumenttype"
              header="Instrument Type"
            ></Column>
            <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="strikeprice"
              header="Strike Price"
            ></Column>
            <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="optiontype"
              header="Option Type"
            ></Column>
            <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              field="orderstatus"
              header="Order Status"
              body={renderOrderStatus}
            ></Column>
            <Column
              align="center"
              style={{ border: "1px solid #ddd" }}
              header="Actions"
              body={(rowData) => (
                <Link
                  to={`/admin/order_book/order_details/${rowData.uniqueorderid}`}
                >
                  <button
                    className="btn btn-primary active btn-xs custom-target-icon"
                    data-pr-tooltip="View Record"
                    data-pr-position="top"
                  >
                    <i className="ri-timeline-view"></i>
                  </button>
                </Link>
              )}
            ></Column>
          </DataTable>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AdminOrderBook;
