import SubHeader from "../component/SubHeader";
import Header from "../component/Header";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Modal, Button } from "react-bootstrap";
import config from "../../../component/config";
import img from "../../../assets/img/avatars/1.png";

import { VirtualScroller } from "primereact/virtualscroller";
import Footer from "../../../component/Footer";
const TimeLine = () => {
  const [loading, setLoading] = useState(false);
  const toast = React.useRef(null);
  const navigate = useNavigate();
  const [reportData, setReportData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [activities, setActivities] = useState([]);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const [instrumentLogData, setInstrumentLogData] = useState(null);
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const authToken = localStorage.getItem("authToken");

        if (!userId) {
          console.error("User ID not found in localStorage");
          return;
        }

        if (!authToken) {
          console.error("Access token not found in localStorage");
          navigate("/commonlogin");
          return;
        }

        const response = await fetch(
          `${config.apiDomain}/api/common/get_profile_details`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({ user_id: userId }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        if (data.st === 1) {
          setUserData(data.user_details); // Corrected from data.user to data.user_details
        } else {
          console.error("Failed to fetch user profile:", data.message);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
        // Check for 401 Unauthorized error
        if (error.response && error.response.status === 401) {
          console.error(
            "Unauthorized access, redirecting to common login screen"
          );
          navigate("/commonlogin"); // Navigate to common login screen on 401 error
        }
      }
    };

    fetchUserProfile();
  }, []); // Ensure the dependency array is empty

  const [showPopup, setShowPopup] = useState(false); // State for displaying the Popup component

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      // Check if it's 9:15 AM or 3:15 PM
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); // Every minute

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // Helper function to determine modal button variant
  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; // Green color for 9:15 AM
    } else if (hours === 15) {
      return "danger"; // Red color for 3:15 PM
    }
    return "secondary"; // Default color
  };

  useEffect(() => {
    fetchReportData();
  }, []);

  const fetchReportData = async () => {
    try {
      const authToken = localStorage.getItem("authToken"); // Fetch the auth token from localStorage
      if (!authToken) {
        throw new Error("Auth token not found");
      }

      const response = await axios.get(
        `${config.apiDomain}/api/teacher/generate_report_log`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
          },
        }
      );

      if (response.data.st === 1) {
        setReportData(response.data.activity[0]);
      } else {
        console.error("Failed to fetch report data:", response.data.msg);
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
      // Check for 401 Unauthorized error
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access, redirecting to common login screen"
        );
        navigate("/commonlogin"); // Navigate to common login screen on 401 error
      }
    }
  };

  const formatDateTime = (datetime) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    return new Date(datetime).toLocaleTimeString("en-US", options);
  };

  const formatDateDay = (datetime) => {
    return new Date(datetime).getDate();
  };

  const formatDateMonth = (datetime) => {
    return new Date(datetime).toLocaleString("default", { month: "short" });
  };

  const formatDateYear = (datetime) => {
    return new Date(datetime).getFullYear();
  };

  const generateReport = async () => {
    try {
      const response = await axios.get(
        `${config.apiDomain}/api/teacher/generate_report`
      );

      if (response.data.st === 1) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: response.data.msg,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.data.msg,
        });
      }
    } catch (error) {
      console.error("Error generating report:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to generate report",
      });
    }
  };

  useEffect(() => {
    console.log("Component mounted or updated.");
  }, []);

  const loadInstrument = async () => {
    console.log("loadInstrument function called");
    setLoading(true); // Start loading state

    try {
      const authToken = localStorage.getItem("authToken"); // Fetch the auth token from localStorage
      if (!authToken) {
        throw new Error("Auth token not found");
      }

      const response = await axios.get(
        `${config.apiDomain}/api/teacher/get_instrument`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
          },
        }
      );

      if (response.data.st === 1) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: response.data.msg,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.data.msg,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Check for 401 Unauthorized error
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access, redirecting to common login screen"
        );
        navigate("/commonlogin"); // Navigate to common login screen on 401 error
      } else {
        const errorMsg = error.response
          ? error.response.data.msg || "Failed to fetch data"
          : error.message || "Failed to fetch data";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

useEffect(() => {
  const fetchInstrumentLog = async () => {
    setIsLoading(true); // Start loading state
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        throw new Error("Auth token not found");
      }

      const response = await axios.get(
        `${config.apiDomain}/api/teacher/get_instrument_log`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data.st === 1) {
        // Update this line to set the first item of the activity array
        setInstrumentLogData(response.data.activity[0]);
      } else {
        console.error("Failed to fetch instrument log:", response.data.msg);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access, redirecting to common login screen"
        );
        navigate("/commonlogin");
      } else {
        const errorMsg = error.response
          ? error.response.data.msg || "Failed to fetch data"
          : error.message || "Failed to fetch data";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
      }
    } finally {
      setIsLoading(false); // End loading state
    }
  };

  fetchInstrumentLog();
}, [navigate]);

  const itemTemplate = (activity, options) => {
    return (
      <div key={options.index} className="mb-2 text-start">
        <small
          className={`mb-1 d-inline ${
            activity.status ? "text-success" : "text-danger"
          }`}
        >
          <small className="text-muted">Status:</small>{" "}
          {activity.status ? "Complete" : "Incomplete"}
        </small>
        <br></br>
        <p className="d-inline">
          Datetime: {formatDateTime(activity.datetime)}{" "}
          {formatDateDay(activity.datetime)}-
          {formatDateMonth(activity.datetime)}-
          {formatDateYear(activity.datetime)}
        </p>
      </div>
    );
  };

  return (
    <div>
      <Header />
      <SubHeader />
      <Toast ref={toast} />

      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <div className="layout-page">
            <div className="content-wrapper">
              <div className="container flex-grow-1 container-p-y">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-style1 text-secondary">
                    <li className="breadcrumb-item">
                      <Link to="/teacher/dashboard" className="text-secondary">
                        <i className="ri-home-7-line ri-lg"></i>
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-secondary"
                      aria-current="page"
                    >
                      Profile
                    </li>
                  </ol>
                </nav>

                <div className="row">
                  <div className="col-12">
                    <div className="card mb-6">
                      <div
                        className="user-profile-header-banner"
                        style={{
                          background:
                            "linear-gradient(90deg, rgba(0,128,255,1) 0%, rgba(0,255,128,1) 100%)",
                          height: "164px",
                          borderTopLeftRadius: "8px",
                          borderTopRightRadius: "8px",
                        }}
                      ></div>
                      <div className="user-profile-header d-flex flex-column flex-lg-row text-sm-start text-center mb-4">
                        <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                          <img
                            src={img}
                            alt="user image"
                            className="d-block h-auto ms-0 ms-sm-5 rounded user-profile-img"
                          />
                        </div>
                        <div className="flex-grow-1 mt-3 mt-lg-5">
                          <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-5 flex-md-row flex-column gap-4">
                            <div className="user-profile-info">
                              {userData ? (
                                <>
                                  <h4 className="mb-2 mt-lg-6">
                                    {" "}
                                    {capitalizeFirstLetter(userData.name)}
                                  </h4>
                                  <ul className="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-4">
                                    <li className="list-inline-item">
                                      <i className="ri-user-settings-line  ri-24px"></i>
                                      <span className="fw-medium">
                                        {" "}
                                        {capitalizeFirstLetter(userData.role)}
                                      </span>
                                    </li>
                                    <li className="list-inline-item">
                                      <i className="ri-mobile-download-line  ri-24px"></i>
                                      <span className="fw-medium">
                                        {" "}
                                        {userData.mobile}
                                      </span>
                                    </li>
                                    <li className="list-inline-item">
                                      <i className="ri-wallet-line  ri-24px"></i>
                                      <span className="fw-medium">
                                        {" "}
                                        Commission: {userData.commission}%
                                      </span>
                                    </li>
                                  </ul>
                                </>
                              ) : (
                                <p>Loading...</p>
                              )}
                            </div>
                            <div className="ms-auto">
                              {userData && (
                                <span
                                  className={`badge bg-success ${
                                    userData.broker_conn_status
                                      ? "bg-success"
                                      : ""
                                  }`}
                                  style={{ fontSize: "14px" }}
                                >
                                  {userData.broker_conn_status && (
                                    <>
                                      <i className="ri-shield-check-line me-1"></i>
                                      Connected
                                    </>
                                  )}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="nav-align-top">
                      <ul className="nav nav-pills flex-column flex-sm-row mb-6 gap-2 gap-lg-0">
                        <li className="nav-item  active">
                          <Link
                            to="/teacher/user_profile"
                            className="nav-link"
                          >
                            <i className="ri-user-3-line me-1_5"></i>Profile
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/teacher/user_profile_report"
                            className="nav-link"
                          >
                            <i className="ri-team-line me-1_5"></i>Reports
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/teacher/user_profile_activity_log"
                            className="nav-link"
                          >
                            <i className="ri-team-line me-1_5"></i>Activity Log
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/teacher/user_profile_instrument_log"
                            className="nav-link active btn btn-primary "
                          >
                            <i className="ri-team-line me-1_5"></i>Load
                            Instrument Log
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/teacher/user_profile_message_log"
                            className="nav-link"
                          >
                            <i className="ri-team-line me-1_5"></i>Message Log
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* <div class="col-xl-6 mb-6 mb-xl-0">
                    <div class="card">
                      <div className="d-flex justify-content-between align-items-center card-header">
                        <h5 className="text-center mb-0 flex-grow-1">
                          Generate Report
                        </h5>
                      </div>

                      <div class="card-body">
                        <ul class="timeline mb-0">
                          <li class="timeline-item timeline-item-transparent">
                            <span class="timeline-point timeline-point-primary"></span>

                            <div class="timeline-event">
                              <div class="timeline-header mb-3">
                                <button
                  className="btn btn-primary active me-3"
                  onClick={generateReport}
                >
               Generate Report
                </button>

                                <br></br>
                              </div>
                              {reportData && ( // Check if reportData is defined
                                <>
                                  <small
                                    className={`mb-1 d-inline ${
                                      reportData?.status
                                        ? "text-success"
                                        : "text-danger"
                                    }`}
                                  >
                                    <small className="text-start text-muted">
                                      Status:
                                    </small>{" "}
                                    {reportData?.status
                                      ? "Complete"
                                      : "Incomplete"}
                                  </small>
                                  <p className="mb-5 text-start">
                                    Datetime:{" "}
                                    {formatDateTime(reportData.datetime)}{" "}
                                    {formatDateDay(reportData.datetime)}-
                                    {formatDateMonth(reportData.datetime)}-
                                    {formatDateYear(reportData.datetime)}
                                  </p>
                                </>
                              )}
                              <div class="d-flex align-items-center mb-4"></div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}

                  <div class="col-xl-6 mb-6 mb-xl-0">
                    <div class="card">
                      <div className="d-flex justify-content-between align-items-center card-header">
                        <h5 className="text-center mb-0 flex-grow-1">
                          Load Instrument Log
                        </h5>
                      </div>

                      <div class="card-body">
                        <ul class="timeline mb-0">
                          {/* <li class="timeline-item timeline-item-transparent">
                            <span class="timeline-point timeline-point-success"></span>
                            <div class="timeline-event">
                              <div class="timeline-header mb-3">
                                <button className="btn btn-primary active text-center" onClick={loadInstrument} >
        {loading ? (
          <>
            <i className="ri-loader-2-line ri-lg me-2"></i> Loading Instruments
          </>
        ) : (
          'Load Instrument'
        )}
      </button>
                                <br></br>
                              </div>
                              <div>
                                {isLoading ? (
                                  <p>Loading...</p>
                                ) : (
                                  <div
                                    style={{
                                      height: "400px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <VirtualScroller
                                      items={activities}
                                      itemSize={50}
                                      itemTemplate={itemTemplate}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </li> */}
                          <div className="">
                            {isLoading ? (
                              <p>Loading...</p>
                            ) : instrumentLogData ? (
                              <div>
                                <p>
                                  <strong>Start Time:</strong>{" "}
                                  {instrumentLogData.start_time}
                                </p>
                                <p>
                                  <strong>End Time:</strong>{" "}
                                  {instrumentLogData.end_time}
                                </p>
                                <p>
                                  <strong>Instrument Count:</strong>{" "}
                                  {instrumentLogData.instrument_count}
                                </p>
                              </div>
                            ) : (
                              <p>No instrument log data available.</p>
                            )}
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- /Timeline Basic --> */}
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default TimeLine;
const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; // Apply custom modal background colors
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};
