import React, { useState, useEffect,useRef } from "react";

import Footer from "../../../component/Footer"
import SubHeaderS from "./SubHeaderS";
import StudentHeader from "./StudentHeader";
import config from "../../../component/config";
import { Toast } from 'primereact/toast';
import { useNavigate } from "react-router-dom";
const Dashboard = () => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate=useNavigate();
  const toast = useRef(null);
  useEffect(() => {
    const fetchData = async () => {
      const authToken = localStorage.getItem("authToken");
  
      if (!authToken) {
      navigate("/commonlogin");
        return;
      }
  
      setLoading(true);
  
      try {
        const response = await fetch(`${config.apiDomain}/api/student/student_home`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        });
  
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
  
        const data = await response.json();
        setMessage(data.msg);
      }catch (error) {
        console.error('Error fetching data:', error);
        // Check for 401 Unauthorized error
        if (error.response && error.response.status === 401) {
          console.error('Unauthorized access, redirecting to common login screen');
          navigate('/commonlogin'); // Navigate to common login screen on 401 error
        } else {
          const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
          setError(new Error(errorMsg));
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: errorMsg,
            life: 3000,
          });
        }
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  return (
    <>
    <Toast ref={toast} />
      <StudentHeader />
      <SubHeaderS />
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
       
      </div>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;



