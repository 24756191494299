


import React, { useState, useEffect,useRef } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer"
import AdminSubHeader from "./AdminSubHeader";
import axios from "axios";
import config from "../../../component/config";
import { Toast } from 'primereact/toast';
const UpdateTeacher = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const studentData = location.state || {};
  const { id } = useParams();
  const [teacherData, setTeacherData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [backClicked, setBackClicked] = useState(false);
  const toast = useRef(null); 
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
  
      try {
        const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage
  
        if (!authToken) {
         navigate("/commonlogin");
         return;
        }
  
        const response = await axios.post(
          `${config.apiDomain}/api/admin/manage_teachers/view`,
          {
            teacher_id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
            },
          }
        );
  
        if (response.data && response.data.st === 1) {
          setTeacherData(response.data.data);
        } else {
          setError(new Error(response.data.msg || "Failed to fetch data"));
        }
           }catch (error) {
      console.error('Error fetching data:', error);
      // Check for 401 Unauthorized error
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized access, redirecting to common login screen');
        navigate('/commonlogin'); // Navigate to common login screen on 401 error
      } else {
        const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
        setError(new Error(errorMsg));
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: errorMsg,
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  
    if (id) {
      fetchData();
    }
  }, [id]); // Dependency array ensures useEffect runs when id changes
  
  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      teacher_id: id,
      name: teacherData.name,
      mobile: teacherData.mobile,
      email: teacherData.email,
      commission: teacherData.commission,
      lot_size_limit: teacherData.lot_size_limit,
      broker_client_id: teacherData.broker_client_id,
      broker_password: teacherData.broker_password,
      broker_qr_totp_token: teacherData.broker_qr_totp_token,
      broker_api_key: teacherData.broker_api_key
    };
  
    try {
      const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage
  
      if (!authToken) {
        throw new Error("Auth token not found");
      }
  
      const response = await axios.put(
        `${config.apiDomain}/api/admin/manage_teachers/update`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
          },
        }
      );
  
      if (response.data && response.data.st === 1) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: formatMessage(response.data.msg) || "Success!",
          className: "text-start",
          life: 3000,
        });

        setTimeout(() => {
          navigate("/admin/manage_teacher");
        }, 1000);
      } else if (response.data && response.data.st === 2) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail:
            formatMessage(response.data.msg) ||
            "Warning: Please check the input.",
          life: 3000,
        });
      } else if (
        response.data &&
        (response.data.st === 3 || response.data.st === 4)
      ) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: formatMessage(response.data.msg) || "An error occurred.",
          life: 3000,
        });
      } else {
        
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Unexpected response status",
          life: 3000,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/commonlogin");
      } else {
        const errorMsg = error.response?.data?.msg
          ? formatMessage(error.response.data.msg)
          : String(error.message || "Failed to fetch data");

        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
          className: "text-start",
        });
      }
    } finally {
      setLoading(false);
    }
  };



  const formatMessage = (msg) => {
    if (typeof msg === "object") {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };
  
  return (
    <div>
     <AdminHeader />
     <AdminSubHeader />
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <div className="Container">
            <div className="container-xxl flex-grow-1 container-p-y">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1">
                  <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-secondary">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/manage_teacher">Manage Teacher</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Update Teacher</li>
                </ol>
              </nav>
              {loading && <div>Loading...</div>}
              {error && <div>{error.message}</div>}
              {teacherData && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card mb-6">
                      <div className="d-flex justify-content-between align-items-center card-header">
                        <button
                          onClick={handleBack}
                          className="btn rounded-pill btn-outline-secondary btn-xs"
                        >
                          <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
                        </button>
                        <h5 className="text-center mb-0 flex-grow-1">Update Teacher</h5>
                      </div>
                      <div className="card-body pt-0">
                        <form id="formAccountSettings" onSubmit={handleSubmit}>
                          <div className="row mt-1 g-3">
                          <h5 className="text-start">
              {" "}
              <i className="ri-user-line ri-ms me-2"></i>
              Personal Information
            </h5>
                            <div className="col-md-3">
                              <div className="form-floating form-floating-outline">
                                <input
                                  className="form-control"
                                  type="text"
                                  id="name"
                                  name="name"
                                  placeholder="Name"
                                  value={teacherData.name}
                                  onChange={(e) =>
                                    setTeacherData({ ...teacherData, name: e.target.value })
                                  }
                                  required
                                />
                                <label htmlFor="name"><span className="text-danger">*</span> Name</label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="input-group input-group-merge">
                                <div className="form-floating form-floating-outline">
                                  <input
                                    type="text"
                                    id="mobileNumber"
                                    name="mobileNumber"
                                    className="form-control"
                                    placeholder="202 555 0111"
                                    value={teacherData.mobile}
                                    onChange={(e) =>
                                      setTeacherData({ ...teacherData, mobile: e.target.value })
                                    }
                                    required
                                  />
                                  <label htmlFor="mobileNumber"><span className="text-danger">*</span> Mobile Number</label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-floating form-floating-outline">
                                <input
                                  className="form-control"
                                  type="email"
                                  id="email"
                                  name="email"
                                  placeholder="john.doe@example.com"
                                  value={teacherData.email}
                                  onChange={(e) =>
                                    setTeacherData({ ...teacherData, email: e.target.value })
                                  }
                                  required
                                />
                                <label htmlFor="email"><span className="text-danger">*</span> E-mail</label>
                              </div>
                            </div>
                            <div className="col-md-3">
  <div className="form-floating form-floating-outline">
    <input
      type="number"
      className="form-control"
      id="lot_size_limit"
      name="lot_size_limit"
      placeholder="Lot Size Limit"
      value={teacherData.lot_size_limit}
      onChange={(e) => {
        let value = e.target.value;
        if (value === "" || (value >= 0 && value <= 5000)) {
          setTeacherData({ ...teacherData, lot_size_limit: value });
        }
      }}
      required
    />
    <label htmlFor="lot_size_limit">
      <span className="text-danger">*</span> Lot Size Limit
    </label>
  </div>
</div>
</div>
                          <div className="row mt-3 g-3">
                            <div className="col-md-3">
                              <div className="form-floating form-floating-outline">
                                <input
                                  type="number"
                                  className="form-control"
                                  id="commission"
                                  name="commission"
                                  placeholder="Commission"
                                  value={teacherData.commission}
                                  onChange={(e) =>
                                    setTeacherData({ ...teacherData, commission: e.target.value })
                                  }
                                  required
                                />
                                <label htmlFor="commission"><span className="text-danger">*</span> Commission</label>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="row mt-3 g-3">
                          <h5 className="text-start">
                    {" "}
                    <i className="ri-group-line ri-ms me-2"></i>
                    Broker Information
                  </h5>
                            <div className="col-md-3">
                              <div className="form-floating form-floating-outline">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="brokerClientId"
                                  name="brokerClientId"
                                  placeholder="Broker Client ID"
                                  value={teacherData.broker_client_id}
                                  onChange={(e) =>
                                    setTeacherData({ ...teacherData, broker_client_id: e.target.value })
                                  }
                                />
                                <label htmlFor="brokerClientId"><span className="text-danger">*</span> Broker Client ID</label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-floating form-floating-outline">
                                <input
                                  type="password"
                                  className="form-control"
                                  id="brokerPassword"
                                  name="brokerPassword"
                                  placeholder="Broker Password"
                                  value={teacherData.broker_password}
                                  onChange={(e) =>
                                    setTeacherData({ ...teacherData, broker_password: e.target.value })
                                  }
                                />
                                <label htmlFor="brokerPassword"><span className="text-danger">*</span> Broker Password</label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-floating form-floating-outline">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="brokerQrTotpToken"
                                  name="brokerQrTotpToken"
                                  placeholder="Broker QR Totp Token"
                                  value={teacherData.broker_qr_totp_token}
                                  onChange={(e) =>
                                    setTeacherData({ ...teacherData, broker_qr_totp_token: e.target.value })
                                  }
                                />
                                <label htmlFor="brokerQrTotpToken"><span className="text-danger">*</span> Broker QR Totp Token</label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-floating form-floating-outline">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="brokerApiKey"
                                  name="brokerApiKey"
                                  placeholder="Broker API Key"
                                  value={teacherData.broker_api_key}
                                  onChange={(e) =>
                                    setTeacherData({ ...teacherData, broker_api_key: e.target.value })
                                  }
                                />
                                <label htmlFor="brokerApiKey"><span className="text-danger">*</span> Broker API Key</label>
                              </div>
                            </div>
                          </div>
                          <Toast ref={toast} />
                          <div className="row">
  <div className="col-5 text-start mb-5 mt-4">
    <button
      onClick={handleBack}
      className="btn rounded-pill btn-outline-secondary btn-xs"
    >
      <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
    </button>
  </div>
  <div className="col-7 text-end mb-5 mt-4">
  <button
                                type="submit"
                                className="btn rounded-pill btn-info btn-sm"
                                disabled={loading}
                              >
                          {loading ? (<div className="spinner-border me-2" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </div>
                                ) : (
                                  <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                                )}{" "}
                                {loading ? "Saving Data" : "Save Data"}
                              
                              </button>
  </div>
</div>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UpdateTeacher;
