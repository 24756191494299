import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Blinker from "./Blinker";
import Sidebar from "./Sidebar"; // Import the sidebar component

const Header = () => {
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const [currentTime, setCurrentTime] = useState(new Date());
  const [sidebarOpen, setSidebarOpen] = useState(false); // State to manage sidebar visibility

  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const userRole = localStorage.getItem("userRole");
  const navigate = useNavigate();

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const monthAbbreviations = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    6: "Jul",
    7: "Aug",
    8: "Sept",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  const formatTime = (date) => {
    const optionsTime = { hour: "2-digit", minute: "2-digit", hour12: true };

    const day = date.toLocaleDateString("en-US", { day: "numeric" });
    const month = monthAbbreviations[date.getMonth()];
    const weekday = date.toLocaleDateString("en-US", { weekday: "short" });

    const formattedDate = `${weekday}, ${day} ${month}`;
    const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

    return { formattedDate, formattedTime };
  };

  const { formattedDate, formattedTime } = formatTime(currentTime);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.clear();
    navigate("/commonlogin");
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleConfirmLogout = () => {
    handleLogout();
    handleCloseModal();
  };

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen); // Toggle sidebar open/close

  return (
    <div>
      <nav className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme">
        <div className="container-xxl d-flex justify-content-between align-items-center ">
          <div className="d-flex align-items-center d-none d-xl-flex py-0 me-6">
            <div className="avatar me-2">
              <img
                src={`${process.env.PUBLIC_URL}/favicon.png`}
                alt="autoprofito Logo"
                className="w-px-40 h-auto rounded-circle"
              />
            </div>
            <span
              className="app-brand-text demo menu-text fw-semibold"
              style={{ fontSize: "22px" }}
            >
              StockSync
            </span>
          </div>
          <div className="d-flex align-items-center d-xl-none">
            <div className="layout-menu-toggle navbar-nav align-items-xl-center me-4 me-xl-0">
              <a
                className="nav-item nav-link px-0 me-xl-6"
                href="#"
                onClick={toggleSidebar}
              >
                <i className="ri-menu-line ri-xl"></i>
              </a>
            </div>
          </div>

          <div className="d-flex align-items-center">
            <div className="d-block d-xl-block me-2">
              <span className="text-secondary">{formattedDate}</span>
              <br />
              <strong className="fs-4">{formattedTime}</strong>
            </div>
            <Blinker
              baseColor="#03A9F4"
              pulseColor="#00FF00"
              baseSize={10}
              pulseSize={15}
            />
          </div>

          <div className="d-flex align-items-center">
            <div className="text-end me-3 d-none d-xl-block">
              <h6 className="mb-0 text-capitalize" style={{ fontSize: "16px" }}>
                {userName}
              </h6>
              <span
                className="badge rounded-pill text-capitalize bg-label-primary"
                style={{ fontSize: "14px" }}
              >
                {userRole}
              </span>
            </div>
            <ul className="navbar-nav flex-row align-items-center ms-auto">
              <li className="nav-item navbar-dropdown dropdown-user dropdown">
                <a
                  className="nav-link dropdown-toggle hide-arrow p-0"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <div className="w-px-40 position-relative">
                    <div className="d-flex justify-content-center align-items-center border border-primary border-3 rounded-circle bg-label-primary avatar-lg">
                      <span className="avatar-initial rounded-circle bg-label-primary">
                        {userName &&
                          userName
                            .split(" ")
                            .map((word) => word.charAt(0).toUpperCase())
                            .join("")}
                      </span>
                    </div>
                  </div>
                </a>
                <ul className="dropdown-menu dropdown-menu-end mt-3 py-2">
                  <li>
                    <Link to="/teacher/user_profile" className="dropdown-item">
                      <i className="ri-user-line ri-22px me-2"></i>
                      Profile
                    </Link>
                  </li>
                  <li>
                    <div className="dropdown-divider"></div>
                  </li>
                  <li>
                    <div className="d-grid px-4 pt-2 pb-1">
                      <div
                        onClick={handleShowModal}
                        className="dropdown-item btn btn-danger d-flex align-items-center"
                      >
                        <i className="ri-logout-box-r-line ri-lg me-1"></i>{" "}
                        Logout
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Sidebar Component */}
      <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />

      {/* Modal for Logout Confirmation */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to logout?</Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between w-100">
            <button
              onClick={handleCloseModal}
              className="btn btn-outline-secondary rounded-pill btn-sm"
            >
              <i className="ri-close-large-line me-2"></i> Cancel
            </button>
            <button
              onClick={handleConfirmLogout}
              className="btn btn-danger rounded-pill btn-sm"
            >
              <i className="ri-logout-box-r-line me-2"></i> Logout
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Header;
